<template>
		<h4 class="text-center fw-light">Gruppenleitung</h4>
		<div class="card mb-4" v-for="leader in groupleaders" :key="leader.id">
			<div v-if="leader.group1_leader == true || leader.group2_leader == true">
				<div class="card-header">
					<router-link :to="{ name: 'Mitglied', params: { id: leader.id } }">
						<h5 class="text-center mb-3" v-text="leader.name" />
					</router-link>
					<span class="d-block w-40px h-4px bg-success mx-auto rounded-3"></span>
				</div>
				<div class="card-body text-center">
					<div class="avatar avatar-xl">
						<img v-if="leader.picture" :src="getImage(leader)">
						<i v-else class="fad fa-4x fa-user" style="color: #51cf66;"></i>
					</div>
					<p v-if="leader.group1_leader" v-text="leader.group1_role"></p>
					<p v-if="leader.group2_leader" v-text="leader.group2_role"></p>
				</div>
			</div>
			<div v-else>
				<div class="card-header">
					<router-link :to="{ name: 'Mitglied', params: { id: leader.id } }">
						<h5 class="text-center mb-3" v-text="leader.name" />
					</router-link>
				<span class="d-block w-40px h-4px bg-success mx-auto rounded-3"></span>
				</div>
				<div class="card-body text-center">
					<div class="avatar avatar-xl">
						<img v-if="leader.picture" :src="getImage(leader)">
						<i v-else class="fad fa-4x fa-user" style="color: #51cf66;"></i>
					</div>
					<p v-if="leader.group1_leader" v-text="leader.group1_role"></p>
					<p v-if="leader.group2_leader" v-text="leader.group2_role"></p>
				</div>
			</div>
		</div>
</template>
<script>
//import _ from 'lodash';
export default {
	props: ['leaders'],
	computed: {
		groupleaders() {
			//return _.orderBy(this.leaders, 'group1_leader', ['desc']);
			return [...this.leaders].sort((a,b) => b.group1_leader - a.group1_leader);
		}
	},
	methods: {
		getImage(member)
		{
			if(member)
			{
				return (member.picture) ? `https://api.bs-rt.de/assets/${member.picture.id}?key=thumbnail-150` : 'https://via.placeholder.com/150';
			}
		},
	}
};
</script>