<template>
	<section class="section">
		<div class="container">
			<div class="row g-3">
				<div class="col-lg-4 pe-xxl-8 d-none d-sm-none d-md-block">
					<div class="row pb-5">
						<GroupLinks v-if="group.subpost.length >= 1" :posts="group.subpost" />
					</div>
					<div class="row">
						<GroupLeaders v-if="group.leaders.length >= 1" :leaders="group.leaders" />
					</div>
				</div>
				<div :class="{'col-lg-8': group.leaders.length >= 1}">
					<article v-if="group.post">
						<p v-html="group.post.text"></p>
					</article>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import GroupLeaders from '@/views/GroupLeaders.vue'
import GroupLinks from '@/views/GroupLinks.vue'
export default {
	name: 'GroupInfoSingle',
	props: ['group'],
	components: {
		GroupLeaders,
		GroupLinks
	},
	methods: {},
	mounted () {
		window.scrollTo(0, 0)
	}
};
</script>
<style scoped>
article p {
	text-align: justify;
}
</style>