<template>
	<div class="group">
		<SectionHeader v-if="group" :image="null" title="Arbeitsgruppe" :page="group.post.title" />
		<GroupInfoSingle v-if="group" :group="group"/>
	</div>
</template>
<script>
import GroupInfoSingle from '@/views/GroupInfoSingle.vue'
import SectionHeader from '@/views/SectionHeader'
export default {
	name: 'Group',
	components: {
		GroupInfoSingle,
		SectionHeader
	},
	props: ["slug"],
	created() {
		this.$store.dispatch('loadGroups');
	},
	methods: {
	},
	computed: {
		group() {
			return this.$store.state.groups.find(g => g.slug == this.slug);
		},
		error() {
			return this.$store.state.loadingError;
		},
		loading() {
			return this.$store.state.loadingStatus;
		}
	},
	mounted() {
	}
}
</script>