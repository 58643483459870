<template>
	<h4 class="text-center fw-light mb-3">Weitere Beiträge</h4>
	<div class="card">
		<div class="card-body">
			<ul class="list-unstyled list-type-01 primary-link font-w-500">
				<li v-for="post in posts" :key="post.id">
					<i class="bi bi-chevron-right text-success"></i>
					<router-link :to="{ name: 'Beitrag', params: { id: post.id } }" v-text="post.title" />
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	name: 'GroupLinks',
	props: ['posts'],

}
</script>